import React from "react";
import { formatDate, formatDateTime } from "../functions/date_format";
import { Link } from "react-router-dom";
import "../custom-css/ConsultationList.css";
import { getLanguageText } from "../functions/getTextLanguage";
import { getLanguageClient } from "../api/api_details";

const ConsultationsList = ({ consultations, handleRecommendationsClick }) => {
  const languageId = getLanguageClient();
  return (
    <div>
      {consultations.length > 0 ? (
        <div>
          <div className="title-consultation-list">
            <h3>{getLanguageText(languageId, "list-title")}</h3>
            <p>{getLanguageText(languageId, "list-subtitle")}</p>
          </div>

          {consultations.map((item, i) => (
            <div key={i} className="consultation-item-client-container">
              <div>
                <div className="consultation-item-title">
                  <i class="fa-solid fa-user-doctor"></i>
                  <div>
                    <h3>
                      {getLanguageText(languageId, "consultation")} #{consultations.length - i}
                    </h3>
                  </div>
                </div>
                <p className="sub-label">
                  <span>Date: </span> {formatDate(item.date)}
                </p>

                <Link
                  className="link-recommendation"
                  to={`/recommendation/${item.id}`}
                >
                  <button className="button-10 button-cell">
                    {getLanguageText(languageId, "recommendation")}
                    <i class="fa-solid fa-chevron-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ConsultationsList;
