import {
  GET_INTAKE_FORM_EN_URL,
  GET_INTAKE_FORM_RESPONSES_URL,
  GET_INTAKE_FORM_RU_URL,
  INSERT_INTAKE_FORM_URL,
  SET_COMPLETED_INTAKE_FORM_URL,
  getSessionClient,
} from "../api_details";

export const intakeEN = async (sessionId) => {
  const apiUrl = GET_INTAKE_FORM_EN_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const intakeRU = async (sessionId) => {
  const apiUrl = GET_INTAKE_FORM_RU_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const intakeResponses = async (sessionId) => {
  const apiUrl = GET_INTAKE_FORM_RESPONSES_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const insertIntakeForm = async (requestBody) => {
  const apiUrl = INSERT_INTAKE_FORM_URL;
  const sessionId = getSessionClient();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const setCompletedForm = async () => {
  const apiUrl = SET_COMPLETED_INTAKE_FORM_URL;
  const sessionId = getSessionClient();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
