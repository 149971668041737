import React from "react";
import "../custom-css/BannerTopContainer.css";

const BannerTopContainer = () => {
  return (
    <div className="banner-top-container">
      <img src="https://api.elenanutritionist.com/uploads/img/banner_top.png"></img>
    </div>
  );
};

export default BannerTopContainer;
