import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import "./App.css";
import LoginPage from "./Login/LoginPage";
import Home from "./Home/Home";
import RecommendationPage from "./RecommendationPage/RecommendationPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/clients/login" element={<ClientPageLogin />} />
        <Route path="/clients/:id" element={<ClientMainPage />} />
        <Route
          path="/recommendation/:consultationId/:session"
          element={<ClientRecommendationPage />}
        /> */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/home/*" element={<Home />} />
        <Route path="/recommendation/:id" element={<RecommendationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
