import React, { useEffect, useState } from "react";
import "../custom-css/Login.css";
import "../custom-css/ConsultationsDetails.css";
import { consultationsClient } from "../api/calls/login";
import {
  getCompletedIntake,
  getLanguageClient,
  getNameClient,
  getSessionClient,
} from "../api/api_details";
import "../custom-css/Home.css";
import IntakeForm from "../IntakeForm/IntakeForm";
import ConsultationsList from "./ConsultationsList";
import BannerTopContainer from "../GeneralComponents/BannerTopContainer";
import { getLanguageText } from "../functions/getTextLanguage";
import ModalConfirmCompleted from "../IntakeForm/Modal/ConfirmCompleteModal";
import CustomLoader from "../GeneralComponents/Loader";

const Home = () => {
  const [consultations, setConsultations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sessionId = getSessionClient();
  const languageId = getLanguageClient();
  const nameClient = getNameClient();
  const completedIntake = getCompletedIntake();

  const handleConfirmComplete = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getConsultations = async () => {
    setIsLoading(true);
    try {
      const response = await consultationsClient(sessionId);

      if (response.success) {
        setConsultations(response.data);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("Home error:", error.message);
      alert("An error occurred while logging in.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultations();
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const handleOpenTelegram = () => {
    window.open("https://t.me/Elena_nutritional_therapy", "_blank");
  };

  return (
    <div className="client-mainpage-container">
      <BannerTopContainer />
      <div className="home-container">
        <div className="home-container-content">
          <h2>
            {getLanguageText(languageId, "Welcome")}, {nameClient}!
          </h2>

          {isLoading ? (
            <div>
              <CustomLoader />
            </div>
          ) : completedIntake === "false" ? (
            <div className="intake-form-container">
              <IntakeForm
                reload={handleReload}
                openModal={handleConfirmComplete}
              />
            </div>
          ) : consultations.length > 0 ? (
            <ConsultationsList consultations={consultations} />
          ) : (
            <div className="message-consultations">
              <p>{getLanguageText(languageId, "message_waiting_1")}</p>
              <p>{getLanguageText(languageId, "message_waiting_2")}</p>
              <p>{getLanguageText(languageId, "message_waiting_3")}</p>
              <div className="btn-telegram-container">
                <button onClick={handleOpenTelegram} className="button-10">
                  <i class="fa-brands fa-telegram"></i> Telegram
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalConfirmCompleted
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirmDone={handleReload}
      />
    </div>
  );
};

export default Home;
