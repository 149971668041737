import React, { useState, useEffect } from "react";
import "../custom-css/App.css";
import "../custom-css/Tables.css";
import RichTextEditor from "../GeneralComponents/RichTextEditor";
import TitleTables from "../GeneralComponents/TitleTables";
import { getLanguageClient, getSessionClient } from "../api/api_details";
import {
  insertIntakeForm,
  intakeEN,
  intakeRU,
  intakeResponses,
} from "../api/calls/intake_form";
import { getLanguageText } from "../functions/getTextLanguage";

const IntakeForm = ({ openModal }) => {
  const [formData, setFormData] = useState([]);
  const [editorHtml, setEditorHtml] = useState({});
  const [questionOrder, setQuestionOrder] = useState([]);
  //   const [showErrorModal, setShowErrorModal] = useState(false);

  const languageId = getLanguageClient();
  const sessionId = getSessionClient();

  const handleOpenCompletedModal = () => {
    handleSaveClick(true);
    openModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (languageId === "2") {
          response = await intakeRU(sessionId);
        } else {
          response = await intakeEN(sessionId);
        }
        if (response.success) {
          const formQuestions = response.data;

          // Make a separate API call to get the saved rich text values
          const savedRichTextResponse = await intakeResponses(sessionId);

          if (savedRichTextResponse.success) {
            // Create a map of question_id to response from the API response

            const initialEditorHtml = {};
            const order = [];
            if (savedRichTextResponse.data.length === 0) {
              formQuestions.forEach((item) => order.push(item.id));
              // const order = formQuestions.map((item) => item.id);
              setQuestionOrder(order);
            } else {
              savedRichTextResponse.data.forEach((item) => {
                initialEditorHtml[item.question_id] = item.response || "";
                order.push(item.question_id); // Push question_id to order
              });

              setEditorHtml(initialEditorHtml);
              setQuestionOrder(order); // Set the order
            }
          }

          setFormData(formQuestions);
        } else {
          console.error("Fetching form data failed:", response.error);
          //   setShowErrorModal(true);
        }
      } catch (error) {
        console.error("Error during form data fetching:", error);
        // setShowErrorModal(true);
      }
    };

    fetchData();
  }, []);

  const handleEditorChange = (html, questionId) => {
    setEditorHtml((prevEditorHtml) => ({
      ...prevEditorHtml,
      [questionId]: html,
    }));
  };

  const handleSaveClick = async (sendFormClicked) => {
    try {
      const arrayRes = [];
      questionOrder.forEach((questionId) => {
        const singleResponse = {
          question_id: questionId,
          textResponse: editorHtml[questionId] || "",
        };
        arrayRes.push(singleResponse);
      });

      const response = await insertIntakeForm({
        responses: arrayRes,
      });
      if (response.success) {
        if (!sendFormClicked) {
          alert(getLanguageText(languageId, "saved_form"));
        }
      } else {
        console.error("Failed to save rich text:", response.error);
      }
    } catch (error) {
      console.error("Error while saving rich text:", error);
    }
  };

  return (
    <div className="form-container questionnaire-container">
      <TitleTables
        title={getLanguageText(languageId, "Intake_title")}
        paragraph={getLanguageText(languageId, "Intake_subtitle")}
        buttonText={getLanguageText(languageId, "btn_save")}
        onClick={() => handleSaveClick(false)}
        btnCompleted={getLanguageText(languageId, "btn_send")}
        onClickCompleted={handleOpenCompletedModal}
      />
      <table className="form-consultation-table">
        <tbody>
          <tr>
            <td>
              <form className="form-consultation">
                {formData.map((item, i) => (
                  <div className="form-group-consultation" key={item.id}>
                    <label htmlFor={`title${item.id}`}>
                      {i + 1} - {item.title}
                    </label>
                    <p className="form-consultation-subtitle">
                      {item.subtitle}
                    </p>
                    <RichTextEditor
                      value={editorHtml[item.id] || ""}
                      onChange={(html) => handleEditorChange(html, item.id)}
                    />
                  </div>
                ))}
              </form>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="btn-line-bottom-form">
        <button onClick={() => handleSaveClick(false)} className="button-10">
          {getLanguageText(languageId, "btn_save")}
        </button>
        <button
          onClick={handleOpenCompletedModal}
          className="button-10 btn-send-bottom"
        >
          {getLanguageText(languageId, "btn_send")}
        </button>
      </div>
    </div>
  );
};

export default IntakeForm;
