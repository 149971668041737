import React, { useState } from "react";
import "../custom-css/App.css";
import "../custom-css/Tables.css";

const TitleTables = ({
  title,
  paragraph,
  buttonText,
  onClick,
  btnCompleted,
  onClickCompleted,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div className="title-table-notes">
        <div>
          <h3>{title}</h3>
          <p>{paragraph}</p>
        </div>
        <div className="btn-title-container">
          <button onClick={onClick} className="button-10 save-form">
            {buttonText}
          </button>
          <button
            onClick={onClickCompleted}
            className="button-10 save-form btn-send-form"
          >
            {btnCompleted}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleTables;
