import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../custom-css/Login.css";
import "../custom-css/App.css";
import { loginClient } from "../api/calls/login";
import { getLanguageText } from "../functions/getTextLanguage";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Clear sessionStorage on component mount
    sessionStorage.clear();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginClient(username, password);

      if (response.success) {
        // Redirect to the dashboard on successful login
        const { session_id, language, completed_intake, name } = response.data;
        sessionStorage.setItem("session-id", session_id);
        sessionStorage.setItem("language-id", language);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("completed_intake", completed_intake);

        // localStorage.setItem("date-test", date);

        navigate(`/home`, { state: { value: response.data } });
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("LoginPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img
          className="img-login"
          src="https://api.elenanutritionist.com/uploads/img/favicon.png"
        ></img>
        <h2>Login</h2>
        <div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="input-password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            onClick={togglePasswordVisibility}
            className="input-password-container"
          >
            <i className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
            <p>{!showPassword ? "Show password" : "Hide password"}</p>
          </div>

          {/* <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
        </div>
        <div className="row-btn-login">
          <button onClick={handleLogin} className="btn-login button-10">
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
