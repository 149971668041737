// languageUtils.js

import en from "../json/en.json"; // English language file
import ru from "../json/ru.json"; // Spanish language file

// Map language IDs to language files
const languageFiles = {
  1: en,
  2: ru,
};

// Function to get language-specific text based on language ID and text identifier
export const getLanguageText = (languageId, textId) => {
  // Get the language file based on the language ID
  const languageFile = languageFiles[languageId];

  // If the language file exists and contains the text identifier, return the corresponding text
  if (languageFile && languageFile[textId]) {
    return languageFile[textId];
  }

  // If the language file or text identifier is not found, return an empty string or a default value
  return "";
};
