import {
  GET_RECOMMENDATIONS_CLIENTS_URL,
  GET_SUPPLEMENTS_CLIENTS_URL,
} from "../api_details";

export const getRecommendations = async (sessionId, consultationId, languageId) => {
  const apiUrl = GET_RECOMMENDATIONS_CLIENTS_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
      "consultation-id": consultationId,
      "language": languageId
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const getSupplements = async (sessionId, consultationId) => {
  const apiUrl = GET_SUPPLEMENTS_CLIENTS_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
      "consultation-id": consultationId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};
