import React, { useEffect, useState } from "react";
import "../custom-css//Login.css";
import "../custom-css/RecommendationPage.css";

import { useNavigate, useParams } from "react-router-dom";
import {
  getRecommendations,
  getSupplements,
} from "../api/calls/recommendations";
import { getLanguageClient, getSessionClient } from "../api/api_details";
import BannerTopContainer from "../GeneralComponents/BannerTopContainer";
import { getLanguageText } from "../functions/getTextLanguage";

const RecommendationPage = () => {
  const { id } = useParams();
  const [recommendations, setRecommendations] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const languageId = getLanguageClient();

  const sessionId = getSessionClient();

  useEffect(() => {
    getRecommendationsClient();
    getSupplementsClient();
  }, []);

  const getRecommendationsClient = async () => {
    try {
      const response = await getRecommendations(sessionId, id, languageId);
      if (response.success) {
        setRecommendations(response.data);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("ClientRecommendationPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  const getSupplementsClient = async () => {
    try {
      const response = await getSupplements(sessionId, id);
      if (response.success) {
        setSupplements(response.data);
      } else {
        alert("There has been an error");
      }
    } catch (error) {
      console.error("ClientRecommendationPage error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return (
    <div className="client-recommendation-page-container">
      <BannerTopContainer />
      <div className="recommendation-section">
        <div>
          <div className="recommendation-title-container">
            <h2>
              <i class="fa-solid fa-notes-medical"></i>
              {getLanguageText(languageId, "recommendation")}
            </h2>
          </div>
          {recommendations.map((recommendation, index) => (
            <div key={index} className="recommendation-container">
              <h3>
                <i class="fa-solid fa-caret-right"></i>
                {recommendation.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: recommendation.response }}
                className="recommendation-response"
              />
            </div>
          ))}
        </div>
      </div>
      {supplements.length === 0 ? null :

        (

          <div className="recommendation-section">
            <div className="recommendation-title-container">
              <h2>
                <i class="fa-solid fa-prescription-bottle"></i>
                {getLanguageText(languageId, "supplements")}
              </h2>
            </div>
            <div className="supplement-weeks-container">
              <p>
                {getLanguageText(languageId, "supplement_weeks")}
                <strong>{supplements.weeks} </strong>
                {" " + getLanguageText(languageId, "weeks")}
                {getLanguageText(languageId, "supplement_week_2")}
                <strong>{supplements.review_weeks}</strong>
                {" " + getLanguageText(languageId, "weeks") + "."}
              </p>
            </div>
            {supplements.length === 0
              ? null
              : supplements.supplements.map((supplement, index) => (
                <div key={index} className="supplement-container">
                  <h3>
                    <i class="fa-solid fa-caret-right"></i>
                    <span
                      onClick={() => window.open(supplement.link, "_blank")}
                      className="supplement-name"
                    >
                      {supplement.name}
                    </span>
                  </h3>
                  <div className="supplement-spec">
                    <div>
                      <img
                        src={supplement.imageUrl}
                        className="supplement-img"
                      ></img>
                    </div>
                    <div className="supp-spec-details">
                      <p>
                        <span>{getLanguageText(languageId, "dose")}:</span>{" "}
                        {supplement.dose}
                      </p>
                      <p>
                        <span>{getLanguageText(languageId, "morning")}:</span>{" "}
                        {supplement.morning}
                      </p>
                      <p>
                        <span>{getLanguageText(languageId, "day")}:</span>{" "}
                        {supplement.day}
                      </p>
                      <p>
                        <span>{getLanguageText(languageId, "evening")}:</span>{" "}
                        {supplement.evening}
                      </p>
                      <p>
                        <span>{getLanguageText(languageId, "notes")}:</span>
                        {supplement.notes_instructions}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )
      }
    </div>
  );
};

export default RecommendationPage;
