// AddClientModal.js
import React, { useState } from "react";
import { getLanguageText } from "../../functions/getTextLanguage";
import { getLanguageClient, getSessionClient } from "../../api/api_details";
import { setCompletedForm } from "../../api/calls/intake_form";
import "../../custom-css/Modal.css";

const ModalConfirmCompleted = ({
  isOpen,
  onClose,
  onConfirmDone,
  handleSaveResponse,
}) => {
  const sessionId = getSessionClient();
  const languageId = getLanguageClient();

  const handleSendForm = async () => {
    try {
      const response = await setCompletedForm(sessionId);

      if (response.success) {
        sessionStorage.setItem("completed_intake", true);
        if (onConfirmDone) {
          onConfirmDone();
        }
        onClose();
        alert(getLanguageText(languageId, "alert_confirm"));
      } else {
        alert("There has been an error. Please contact Elena.");
      }
    } catch (error) {
      console.error("Home error:", error.message);
      alert("An error occurred while logging in.");
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal add-client-modal">
        <div className="modal-content">
          <h2>
            <i class="fa-solid fa-circle-check"></i>
            {getLanguageText(languageId, "modal_confirm")}
          </h2>

          <div className="modal-buttons">
            <button onClick={onClose} className="button-12">
              {getLanguageText(languageId, "cancel")}
            </button>
            <button onClick={handleSendForm} className="button-10">
              {getLanguageText(languageId, "confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalConfirmCompleted;
