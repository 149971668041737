export const getSessionClient = () => sessionStorage.getItem("session-id");
export const getLanguageClient = () => sessionStorage.getItem("language-id");
export const getNameClient = () => sessionStorage.getItem("name");
export const getCompletedIntake = () =>
  sessionStorage.getItem("completed_intake");

export const domain = "https://api.elenanutritionist.com/";

export const CLIENTS_LOGIN_URL = `${domain}api/clients/v1/login`;
export const CONSULTATIONS_CLIENTS_LOGIN_URL = `${domain}api/clients/v1/consultations`;
export const GET_RECOMMENDATIONS_CLIENTS_URL = `${domain}api/clients/v1/recommendations`;
export const GET_SUPPLEMENTS_CLIENTS_URL = `${domain}api/clients/v1/supplements`;

export const GET_INTAKE_FORM_RESPONSES_URL = `${domain}api/clients/v1/getIntakeClientResponse`;
export const GET_INTAKE_FORM_RU_URL = `${domain}api/clients/v1/getIntakeClientRu`;
export const GET_INTAKE_FORM_EN_URL = `${domain}api/clients/v1/getIntakeClientEn`;
export const INSERT_INTAKE_FORM_URL = `${domain}api/clients/v1/insertIntakeForm`;
export const SET_COMPLETED_INTAKE_FORM_URL = `${domain}api/clients/v1/setCompletedFormClient`;
