export function formatDate(isoDateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(isoDateString);
  return date.toLocaleDateString(undefined, options);
}

export function getTimeFromDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

export function formatDateTime(isoDateTimeString) {
  const date = new Date(isoDateTimeString);

  // Get the day, month, and year
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Get the hours and minutes
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} at ${hours}:${minutes}`;
}
