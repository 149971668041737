import {
  CLIENTS_LOGIN_URL,
  CONSULTATIONS_CLIENTS_LOGIN_URL,
  // RECOMMENDATIONS_CLIENTS_LOGIN_URL,
  // SUPPLEMENTS_CLIENTS_LOGIN_URL,
} from "../api_details";

// Function to perform login API call
export const loginClient = async (username, password) => {
  const apiUrl = CLIENTS_LOGIN_URL;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: username, password: password }),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

export const consultationsClient = async (sessionId) => {
  const apiUrl = CONSULTATIONS_CLIENTS_LOGIN_URL;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "session-id": sessionId,
    },
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (response.ok) {
      const data = await response.json();

      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("An error occurred while logging in.");
  }
};

// export const recommendationsClient = async (sessionId, consultationId) => {
//   const apiUrl = RECOMMENDATIONS_CLIENTS_LOGIN_URL;
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "session-id": sessionId,
//       "consultation-id": consultationId,
//     },
//   };

//   try {
//     const response = await fetch(apiUrl, requestOptions);

//     if (response.ok) {
//       const data = await response.json();

//       return { success: true, data };
//     } else {
//       const errorData = await response.json();
//       return { success: false, error: errorData };
//     }
//   } catch (error) {
//     console.error("Error during login:", error);
//     throw new Error("An error occurred while logging in.");
//   }
// };

// export const supplementsClients = async (sessionId, consultationId) => {
//   const apiUrl = SUPPLEMENTS_CLIENTS_LOGIN_URL;
//   const requestOptions = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "session-id": sessionId,
//       "consultation-id": consultationId,
//     },
//   };

//   try {
//     const response = await fetch(apiUrl, requestOptions);

//     if (response.ok) {
//       const data = await response.json();

//       return { success: true, data };
//     } else {
//       const errorData = await response.json();
//       return { success: false, error: errorData };
//     }
//   } catch (error) {
//     console.error("Error during login:", error);
//     throw new Error("An error occurred while logging in.");
//   }
// };
